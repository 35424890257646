<template>
  <b-row>
    <b-col>
      <b-card>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="35"
              height="35"
              viewBox="0 0 172 172"
              style=" fill:#000000; cursor: pointer"
              @click="$router.go(-1)"
            ><g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-size="none"
              style="mix-blend-mode: normal"
            ><path
              d="M0,172v-172h172v172z"
              fill="none"
            /><g fill="#08071a"><path d="M86,154.8c37.9948,0 68.8,-30.8052 68.8,-68.8c0,-37.9948 -30.8052,-68.8 -68.8,-68.8c-37.9948,0 -68.8,30.8052 -68.8,68.8c0,37.9948 30.8052,68.8 68.8,68.8zM53.27987,81.94653l22.93333,-22.93333c1.118,-1.118 2.58573,-1.67987 4.05347,-1.67987c1.46773,0 2.93547,0.56187 4.05347,1.67987c2.24173,2.24173 2.24173,5.8652 0,8.10693l-13.14653,13.14653h43.49307c3.1648,0 5.73333,2.5628 5.73333,5.73333c0,3.17053 -2.56853,5.73333 -5.73333,5.73333h-43.49307l13.14653,13.14653c2.24173,2.24173 2.24173,5.8652 0,8.10693c-2.24173,2.24173 -5.8652,2.24173 -8.10693,0l-22.93333,-22.93333c-2.24173,-2.24173 -2.24173,-5.8652 0,-8.10693z" /></g></g></svg>

            <div class="titulo-pagina ml-1">
              <div style="align-self: center">
                Cadastrar cliente
              </div>
            </div>
          </div>
        </div>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group

              label="* Nome:"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="nome"
                placeholder="Nome do Cliente"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group

              label="* E-Mail:"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="E-Mail"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group

              label="CPF/CNPJ:"
              label-for="cpfcnpj"
            >
              <the-mask
                v-model="cnpj"
                class="form-control"
                placeholder="CPF/CNPJ"
                :mask="['###.###.###-##', '##.###.###/####-##']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group

              label="Telefone/Celular:"
              label-for="telefone"
            >
              <the-mask
                v-model="telefone"
                class="form-control"
                placeholder="Telefone/Celular"
                :mask="['(##) ####-####', '(##) #####-####']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group

              label="CEP"
              label-for="cep"
            >
              <the-mask
                v-model="cep"
                class="form-control"
                placeholder="CEP"
                :mask="'#####-###'"
                @input="buscaCep"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group

              label="Endereço"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="endereco"
                disabled
                placeholder="Endereço do cliente"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group

              label="Numero"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="numero"
                placeholder="Numero"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group

              label="Estado"
              label-for="estado"
            >
              <b-form-input
                id="estado"
                v-model="estado"
                disabled
                placeholder="UF"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group

              label="Cidade:"
              label-for="cidade"
            >
              <b-form-input
                id="cidade"
                v-model="cidade"
                disabled
                placeholder="Cidade"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col style="align-self: center">
            <div style="display: flex">
              <b-button
                variant="primary"
                class="px-3"
                @click="SalvarCliente()"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                Salvar Cliente
              </b-button>

              <div
                style="align-self: center"
                class="ml-2"
                @click="LimparCampos()"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                Limpar Campos
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'IndexCliente',
  components: {},
  data() {
    return {
      url: 'admin/cliente',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: '',
      nome: '',
      email: '',
      cnpj: '',
      telefone: '',
      cep: '',
      endereco: '',
      estado: '',
      cidade: '',
      numero: '',
    }
  },

  async created() {
    this.BuscarDados(this.url)
  },
  methods: {
    buscaCep() {
      if (this.cep.length > 7) {
        this.$http.get(`admin/busca_cep/${this.cep}`).then(resp => {
          this.endereco = resp.data.logradouro
          this.cidade = resp.data.localidade
          this.estado = resp.data.uf
        })
      }
    },
    BuscarDados() {
      this.clientes = JSON.parse(localStorage.getItem('admin/edicao'))
      this.id = this.clientes.id
      this.nome = this.clientes.nome
      this.email = this.clientes.email
      this.cnpj = this.clientes.cnpj
      this.telefone = this.clientes.telefone
      this.cep = this.clientes.cep
      this.endereco = this.clientes.endereco
      this.numero = this.clientes.numero
      this.estado = this.clientes.estado
      this.cidade = this.clientes.cidade
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    LimparCampos() {
      this.id = null
      this.nome = null
      this.email = null
      this.cnpj = null
      this.telefone = null
      this.cep = null
      this.endereco = null
      this.estado = null
      this.cidade = null
    },
    async SalvarCliente() {
      const obj = {
        nome: this.nome,
        email: this.email,
        cnpj: this.cnpj,
        telefone: this.telefone,
        cep: this.cep,
        endereco: this.endereco,
        numero: this.numero,
        estado: this.estado,
        cidade: this.cidade,
      }
      this.$loading(true)
      if (this.id === null) {
        await this.$http.post('admin/cliente', obj).then(resp => {
          localStorage.setItem('admin/clientes', JSON.stringify(resp.data.cliente))
          this.$loading(false)
          this.$router.push({
            name: 'adm-contrato-ver',
            params: {
              id: resp.data.cliente.id,
            },
          })
        })
      }
      if (this.id) {
        this.$http.put(`admin/cliente/${this.id}`, obj).then(resp => {
          if (resp.data.success) {
            this.$loading(false)
            this.$router.push({
              name: 'adm-cliente',
            })
            this.Notificacao('success', resp.data.success)
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
